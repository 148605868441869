
import { auth } from '@/lib/auth'
import { store } from '@/lib/store'
import qs from 'qs'

// Views
const Auth = () => import(/* webpackChunkName: "auth" */ '@/views/Auth')
const Main = () => import(/* webpackChunkName: "main" */ '@/views/Main')
const Public = () => import(/* webpackChunkName: "public" */ '@/views/Public')

// Partials
const AuthLogin = () => import(/* webpackChunkName: "auth" */ '@/views/partials/AuthLogin')
const AuthVerify = () => import(/* webpackChunkName: "auth" */ '@/views/partials/AuthVerify')
const Activity = () => import(/* webpackChunkName: "main" */ '@/views/partials/Activity')
const Opportunity = () => import(/* webpackChunkName: "opportunity" */ '@/views/partials/Opportunity')
const Opportunities = () => import(/* webpackChunkName: "main" */ '@/views/partials/Opportunities')
const Organizations = () => import(/* webpackChunkName: "main" */ '@/views/partials/Organizations')
const Presentation = () => import(/* webpackChunkName: "public" */ '@/views/partials/Presentation')
const Settings = () => import(/* webpackChunkName: "main" */ '@/views/partials/Settings')
const Tools = () => import(/* webpackChunkName: "main"*/'@/views/partials/Tools')
const Users = () => import(/* webpackChunkName: "main" */ '@/views/partials/Users')
const Rates = () => import(/* webpackChunkName: "main" */ '@/views/partials/Rates')
const Bval = () => import(/* webpackChunkName: "bval" */ '@/views/partials/Bval')

const ModalOrganization = () => import(/* webpackChunkName: "organizations" */ '@/components/ModalOrganization')
const ModalTrade = () => import(/* webpackChunlName: "tools" */ '@/components/ModalTrade')
const ModalUser = () => import(/* webpackChunkName: "users" */ '@/components/ModalUser')


// Components
const ModalPresentation = () => import(/* webpackChunkName: "opportunity" */ '@/components/ModalPresentation')

// BVAL Components
const BvalTradeLog = () => import(/* webpackChunkName: "bval" */ '@/components/bval/TradeLog')
const BvalExecutiveSummary = () => import(/* webpackChunkName: "bval" */ '@/components/bval/ReportExecutiveSummary')
const BvalDailyQc = () => import(/* webpackChunkName: "bval" */ '@/components/bval/ReportDailyQc')
const BvalBaseSummary = () => import(/* webpackChunkName: "bval" */ '@/components/bval/ReportBaseSummary')
const BvalBdCollateral = () => import(/* webpackChunkName: "bval" */ '@/components/bval/ReportBdCollateral')

/// Functions
const authGuard = async (to, from, next) => {
	let user = auth.getUser()
	if (to.query && to.query.token) {
		try {
			next({ name: 'verify' })
			await store.dispatch('user/verify', to.query.token)
			return next({ name: to.name, query: {}, params: to.params })
		} catch (error) {
			store.dispatch('user/authError', error.message || error)
			return next({ name: 'verify' })
		}
	} else if (to.query && to.query.logout) {
		try {
			await store.dispatch('user/logout')
			return next({ name: to.name, query: {}, params: to.params })
		} catch (error) {
			store.dispatch('user/authError', error.message || error)
			return next({ name: 'verify' })
		}
	}

	if (user) {
		return next()
	} else {
		if (to.query && to.query.token) {
			try {
				next({ name: 'verify' })
				await store.dispatch('user/verify', to.query.token)
				return next({ name: to.name, query: { }})
			} catch (error) {
				store.dispatch('user/authError', error.message || error)
				return next({ name: 'verify' })
			}
		} else if (to.query && to.query.logout) {
			try {
				await store.dispatch('user/logout')
				next({ name: to.name, query: { } })
			} catch (error) {
				store.dispatch('user/authError', error.message || error)
				return next({ name: 'verify' })
			}
		} else {
			let current = window.location.href.replace(window.location.origin,'')
			let login_url = [process.env.VUE_APP_PORTAL_URL + 'authenticate?app=arc', current].join('&redirect_uri=')
			return window.location.href = login_url
		}
	}
}

const bvalGuard = async (to, from, next) => {
	const hasBval = store.getters['user/hasBval'] || store.getters['user/hasBvalSsb']
	if (hasBval)
		return next()
	return next({ name: 'main'})
}

const mainComponents = (content, modal) => ({
	content,
	modal,
})

const authComponents = (content, modal) => ({
	content,
	modal,
	// sidebar: AuthSidebar,
})

const publicComponents = (content, modal) => ({
	content,
	modal,
})

export const routes = [
	{
		path: '/',
		redirect: '/opportunities',
		component: Main,
		children: [
			{
				name: 'main',
				path: 'opportunities',
				beforeEnter: authGuard,
				components: mainComponents(Opportunities),
			},
			{
				name: 'opportunityNew',
				path: 'opportunity/new',
				beforeEnter: authGuard,
				components: mainComponents(Opportunity),
			},
			{
				name: 'opportunity',
				path: 'opportunity/:opportunityId/:tab?',
				beforeEnter: authGuard,
				components: mainComponents(Opportunity),
				children: [
					{
						name: 'opportunityPresentation',
						path: 'presentation/:presentationId?',
						components: {
							pageModal: ModalPresentation,
						},
						meta: {
							modalTitle: 'Share Presentation',
						},
					},
				]
			},
			{
				name: 'rates',
				path: 'rates',
				beforeEnter: authGuard,
				components: mainComponents(Rates),
			},
			{
				name: 'settings',
				path: 'settings/:tab?',
				beforeEnter: authGuard,
				components: mainComponents(Settings),
			},
			{
				path: 'bval',
				beforeEnter: async (to, from, next) => {
					await authGuard(to, from, next)
					let user = auth.getUser()
					window.location.replace( (process.env.PORTAL_URL || process.env.VUE_APP_PORTAL_URL).replace('services','bval') + `?${qs.stringify({ token: user.token })}` )
				},
				components: mainComponents(Bval),
				children: [
					{
						name: 'bval',
						path: '',
						beforeEnter: bvalGuard,
						components: {
							bval: BvalTradeLog,
						},
						children: [
							{
								name: 'tradeEdit',
								path: 'trade/:counterparty/:tradeId?',
								components: {
									bvalModal: ModalTrade,
								},
								meta: {
									modalTitle: 'Edit Trade',
								},
							},
						]
					},
					{
						name: 'bvalExecutiveSummary',
						path: 'executive-summary',
						beforeEnter: bvalGuard,
						components: {
							bval: BvalExecutiveSummary,
						}
					},
					{
						name: 'bvalDailyQc',
						path: 'daily-qc',
						beforeEnter: bvalGuard,
						components: {
							bval: BvalDailyQc,
						},
					},
					{
						name: 'bvalBaseSummary',
						path: 'base-summary',
						beforeEnter: bvalGuard,
						components: {
							bval: BvalBaseSummary
						},
					},
					{
						name: 'bvalBdCollateral',
						path: 'bd-collateral',
						beforeEnter: bvalGuard,
						components: {
							bval: BvalBdCollateral,
						},
					},
				],
			},
			{
				name: 'tools',
				path: 'tools/:tab?',
				beforeEnter: authGuard,
				components: mainComponents(Tools),
				children: [

				],
			},
			{
				name: 'activity',
				path: 'activity',
				beforeEnter: authGuard,
				components: mainComponents(Activity),
			},
			{
				name: 'organizations',
				path: 'organizations',
				beforeEnter: authGuard,
				components: mainComponents(Organizations),
				children: [
					{
						name: 'organization',
						path: '/organization/:orgId?',
						components: {
							pageModal: ModalOrganization,
						},
						meta: {
							modalTitle: `Manage Organization`,
						},
					},
				]
			},
			{
				name: 'users',
				path: 'users',
				beforeEnter: authGuard,
				components: mainComponents(Users),
				children: [
					{
						name: 'user',
						path: '/user/:uid?',
						components: {
							pageModal: ModalUser,
						},
						meta: {
							modalTitle: `Manage User`,
						},
					},
				]
			},
		],
	},
	{
		path: '/authenticate',
		component: Auth,
		children: [
			{
				name: 'login',
				path: '',
				components: authComponents(AuthLogin),
			},
			{
				name: 'verify',
				path: 'verify',
				components: authComponents(AuthVerify)
			}
		],
	},
	{
		path: '/presentation',
		component: Public,
		children: [
			{
				name: 'presentation',
				path: ':opportunityId/:presentationId',
				components: publicComponents(Presentation),
			},
		],
	},
]
